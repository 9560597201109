<template>
  <div class="paySuccess">
    <!--定义Vue组件的模板内容-->

    <div class="formBox center">
      <i class="el-icon-success"> </i>
      <p class="descriptionStatus">{{ $t("充值成功") }}</p>
      <el-button type="danger" size="small" @click="back()">{{ $t("返回") }}</el-button>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  name: "PaySuccess",
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    // xxx: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
    //   type: Object,
    //   required: true
    // }
  },

  // 私有数据
  data() {
    return {};
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() { },
  // 处理函数
  methods: {
    back() {
      this.$router.push("/payForm");
    }
  },

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    // ...mapState([''])
  }

  // ...其他业务逻辑
};
</script>

<style lang='less' scoped>
/**定义组件的形式 */
.paySuccess {
    .center{
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
      .formBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        i {
          font-size: 10rem;
          margin-bottom: 1rem;
          color: #21d161;
        }
        .descriptionStatus{
          font-size: 1.7rem;
          font-weight: 600;
          margin: 5px 0;
        }
        .descriptionCause{
          font-size: 1.4rem;
          font-weight: 500;
          margin: 5px 0 0 0;
          text-align: center;
        }
        .el-button{
            margin-top: 1.5rem;
        }
      }
}
</style>
